/* 定义一个垂直滚动的容器，容器背景颜色为黑色 */
.snap-container {
  min-width: 640px;
  /* 设置容器背景颜色为黑色 */
  background-color: black;
  /* 当内容超出容器高度时，显示垂直滚动条 */
  overflow-y: scroll;
  /* 隐藏Firefox浏览器的滚动条 */
  scrollbar-width: none; /* Firefox */
  /* 隐藏Internet Explorer 10+浏览器的滚动条 */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  
}

/* 隐藏WebKit浏览器（如Chrome和Safari）的滚动条 */
.snap-container::-webkit-scrollbar {
  /* 设置滚动条不显示 */
  display: none; /* WebKit */
}

/* 定义每个子元素的样式，高度为视口高度的100%，并在滚动时自动吸附到容器顶部 */
.snap-section {
  /* 设置子元素高度为视口高度的100% */
  height: 100vh;
  /* 设置子元素在滚动时自动吸附到容器顶部 */
  scroll-snap-align: start;
  /* 确保子元素在滚动时始终吸附到容器顶部 */
  scroll-snap-stop: always;
}

/*图片居中*/
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*视频居中*/
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*视频按钮*/
.video-container {
  /* 使用 Flex 布局，使内容水平和垂直居中 */
  display: flex;
  /* 水平居中内容 */
  justify-content: center;
  /* 垂直居中内容 */
  align-items: center;
  /* 确保容器是相对定位的，以便按钮可以绝对定位在其中 */
  position: relative; 
}

/* 视频容器内的按钮样式 */
.video-container button {
  /* 确保按钮在视频之上 */
  z-index: 1; 
}

/* 定义一个名为 slideUp 的动画 */
@keyframes slide-up {
  0% {
    /* 开始时，元素完全透明且向下偏移 40px */
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    /* 结束时，元素完全不透明且回到原始位置 */
    opacity: 1;
    transform: translateY(0);
  }
}

/* 应用 slideUp 动画的类 */
.animate-slide-up {
  /* 应用 slideUp 动画，持续时间为 0.5 秒，缓动函数为 ease-in-out，动画结束后保持最终状态 */
  animation: slide-up 0.5s ease-in-out forwards;
}
